import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import at from "v-at";
import i18n from "i18n-js";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {
  DEFAULT,
  SETTINGS,
  PAYMENTS,
  VERIFICATION,
  NEW,
  VERIFIED,
  REQUESTED,
} from "../../constants/types/types";
import Button from "../button/Button";

const PartnerProfile = (props) => {
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  const theme = useTheme();
  const [view, setView] = React.useState(VERIFICATION);
  const renderVerification = () => {
    return (
      <Box width="100%" p={2}>
        <Box
          id="banner_label"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          {!at(props, "partner.has_documents") &&
          !at(props, "partner.verification_status") ? (
            <Box
              id="banner_request_documents"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.error.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("banner_request_documents")}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.requestDocuments}
                disableElevation
              >
                {i18n.t("request")}
              </Button>
            </Box>
          ) : !at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === REQUESTED ? (
            <Box
              id="banner_requested"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.warning.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("banner_requested")}
              </Typography>
            </Box>
          ) : at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === NEW ? (
            <Box
              id="verify_banner"
              alignItems="center"
              justifyContent="space-between"
              pl={2}
              pr={2}
              display="flex"
              width={"100%"}
              height={80}
              bgcolor={theme.palette.info.light}
              borderRadius={16}
            >
              <Typography variant="body2" color="secondary">
                {i18n.t("verify_banner")}
              </Typography>
            </Box>
          ) : at(props, "partner.has_documents") &&
            at(props, "partner.verification_status") === VERIFIED ? (
                <Box
                id="completed_banner"
                alignItems="center"
                justifyContent="space-between"
                pl={2}
                pr={2}
                display="flex"
                width={"100%"}
                height={80}
                bgcolor={theme.palette.success.light}
                borderRadius={16}
              >
                <Typography variant="body2" color="secondary">
                  {i18n.t("completed_banner")}
                </Typography>
              </Box>
          ) : (
            
                 <Box
                id="rejected_banner"
                alignItems="center"
                justifyContent="space-between"
                pl={2}
                pr={2}
                display="flex"
                width={"100%"}
                height={80}
                bgcolor={theme.palette.error.main}
                borderRadius={16}
              >
                <Typography variant="body2" color="secondary">
                  {i18n.t("rejected_banner")}
                </Typography>
              
            </Box>
          )}
        </Box>
        {(at(props, "partner.has_documents") &&
        at(props, "partner.verification_status") === NEW) || (at(props, "partner.has_documents") &&
        at(props, "partner.verification_status") === VERIFIED)  ? (
          <Box id="document_verify">
            <Box id="id_docs" mt={2} display="flex" flexDirection="row">
              <Box id="id_front" ml={2}>
                <Typography variant="subtitle2">
                  {i18n.t("id_front_label")}
                </Typography>
                <img
                  src={at(props, "partner.id_front_url")}
                  style={{ height: 400 }}
                  onClick={() => window.open(at(props, "partner.id_front_url"))}
                  alt="id_front"
                />
              </Box>
              <Box id="id_back" ml={2}>
                <Typography variant="subtitle2">
                  {i18n.t("id_back_label")}
                </Typography>
                <img
                  src={at(props, "partner.id_back_url")}
                  style={{ height: 400 }}
                  onClick={() => window.open(at(props, "partner.id_back_url"))}
                  alt="id_front"
                />
              </Box>
              {at(props, "partner.document_url") ? (
                <Box id="document" ml={2}>
                  <Typography variant="subtitle2">
                    {i18n.t("insurance")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.open(at(props, "partner.document_url"))
                    }
                    disableElevation
                  >
                    {i18n.t("view")}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
        {at(props, "partner.has_documents") &&
        at(props, "partner.verification_status") === NEW ? (
          <Box id="action" display="flex" flexDirection="row" mt={2}>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.verifyClick()}
                disableElevation
              >
                {i18n.t("verify")}
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.rejectClick()}
                disableElevation
              >
                {i18n.t("reject")}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    );
  };

  return (
    <Box
      id="main"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Box
        id="header"
        width="100%"
        height={60}
        bgcolor={theme.palette.secondary.light}
        boxShadow={`0 4px 2px -2px ${theme.palette.text.disabled}`}
      >
        <Box m={2} display="flex" flexDirection="row" alignItems="center">
          <IconButton onClick={props.onBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{i18n.t("partner_profile")}</Typography>
        </Box>
      </Box>
      <Box
        id="main_content"
        mt={2}
        bgcolor={theme.palette.secondary.light}
        flexGrow={1}
        width="100%"
        flexDirection="row"
        height="75vh"
        display="flex"
      >
        <Box
          id="side_nav"
          width={250}
          height={"100%"}
          border={1}
          borderLeft={0}
          borderTop={0}
          borderBottom={0}
          borderColor={theme.palette.text.disabled}
        >
          <Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <PersonIcon color={view === DEFAULT ? "primary" : "disabled"} />
              </IconButton>
              <Typography
                color={view === DEFAULT ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("profile")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <VerifiedUserIcon
                  color={view === VERIFICATION ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === VERIFICATION ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("verification")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <ReceiptIcon
                  color={view === PAYMENTS ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === PAYMENTS ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("payments")}
              </Typography>
            </Box>
            <Box
              height={48}
              p={2}
              border={1}
              borderLeft={0}
              borderTop={0}
              borderRight={0}
              borderColor={theme.palette.text.disabled}
              display="flex"
              alignItems="center"
            >
              <IconButton>
                <SettingsIcon
                  color={view === SETTINGS ? "primary" : "disabled"}
                />
              </IconButton>
              <Typography
                color={view === SETTINGS ? "primary" : "textSecondary"}
                variant="body2"
              >
                {i18n.t("settings")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box id="content" width="100%">
          {view === VERIFICATION && renderVerification()}
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerProfile;
