/* eslint-disable react/display-name */
import React, { createContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const FirebaseContext = createContext(null);
export { FirebaseContext };

const useFirebase = ({ children }) => {
  const firebaseConfig = {
    apiKey: "AIzaSyBgxlkl_oh-dWBbSZU_YFLZXsoVvU-6np8",
    authDomain: "nestease-admin-a0904.firebaseapp.com",
    projectId: "nestease-admin-a0904",
    storageBucket: "nestease-admin-a0904.appspot.com",
    messagingSenderId: "367194494050",
    appId: "1:367194494050:web:2b2096b0bd931bca552d3b",
    measurementId: "G-XX8VQ7VL1C"
  };

  // if (location.hostname === "localhost") {

  //    firebaseConfig.databaseURL = {
  //     // Point to the RTDB emulator running on localhost.
  //     // In almost all cases the ns (namespace) is your project ID.
  //     databaseURL: "http://localhost:9000?ns=YOUR_DATABASE_NAMESPACE"
  //   }
  // }

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().settings.appVerificationDisabledForTesting = true;
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default useFirebase;
