import axios from 'axios';
const ProductionApiBase = {
    baseUrl: "https://us-central1-nestease-admin-a0904.cloudfunctions.net/AdminFunctions",
}

// const developmentApiBase = {
//     baseUrl: "https://us-central1-nestease-admin-a0904.cloudfunctions.net/adminFunctions",
// }
// "http://localhost:5001/nestease-66d12/us-central1/widgets/",
// https://a8c10491a521.ngrok.io

////////////////Select Api Base////////////////////

const apiBase = ProductionApiBase;

///////////////////////////////////////////////////

export const apiInstance = axios.create({
    baseURL: apiBase.baseUrl
});

export const axiosInstance = axios.create({});
