import firebase from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBgxlkl_oh-dWBbSZU_YFLZXsoVvU-6np8",
  authDomain: "nestease-admin-a0904.firebaseapp.com",
  projectId: "nestease-admin-a0904",
  storageBucket: "nestease-admin-a0904.appspot.com",
  messagingSenderId: "367194494050",
  appId: "1:367194494050:web:2b2096b0bd931bca552d3b",
  measurementId: "G-XX8VQ7VL1C"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}


export async function getCurrentUser() {
 
  const user = await firebase.auth().currentUser;
  if (user) {
   
    return user;
  }
  return false;
}
export async function getCurrentUserId() {
 
  const user = await firebase.auth().currentUser;
  if (user) {
   
    return user.uid;
  }
  return false;
}

export async function getToken() {
  const user = await firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return token;
  }
  return null;
}


export async function updateProfile(data) {
  const user = await getCurrentUser();

  user
    .updateProfile(data)
    .then(function () {
      return true;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function deleteUser(data) {
  const user = await getCurrentUser();

  user
    .delete()
    .then(function () {
      return true;
    })
    .catch(function (error) {
      throw error;
    });
}
