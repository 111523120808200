import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import moment from "moment";
import at from "v-at";
import {
  FETCH_DATA_PARTNER,
  IS_INITIATED,
  GET,
  IS_DONE,
  DEFAULT,
  DETAILED,
  FETCH_PARTNER,
  VERIFICATION_EXECUTE,
  POST,
  REQUEST,
  REJECT,
  VERIFY,
  IS_ERROR
} from "../../constants/types/types";
import { clearData, fetchData } from "../../store/actions/FetchData";
import { clearDataExecuteData, executeData } from "../../store/actions/ExecuteData";
import CircularProgress from '../../components/progress/CircularProgress';
import Button from "../../components/button/Button";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Table from "../../components/table";
import PartnerProfile from "../../components/profile/PartnerProfile";
import { useHistory, useLocation } from "react-router-dom";
import FullScreenDialog from "../../components/dialog/FullScreenModal";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const header = [
  {
    id: "displayName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    type: "string",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone",
    type: "string",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    type: "string",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
    type: "chip",
  },
  {
    id: "region",
    numeric: false,
    disablePadding: false,
    label: "Region",
    type: "chip",
  },
  {
    id: "creationTime",
    numeric: true,
    disablePadding: false,
    label: "Join Date",
    type: "string",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    type: "button",
  },
];

const Partner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const fetchDataResponse = useSelector((state) => {
    return state.FetchData;
  });
  const executeDataResponse = useSelector((state) => {
    return state.ExecuteData;
  });
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [partner, setPartner] = React.useState(null);
  const [view, setView] = React.useState(query.get("id") ? DETAILED : DEFAULT);
  const [usersAndToken, setUsersAndToken] = React.useState([]);
  const [notificationMessage, setNotificationMessage] = React.useState(null);
  const [notificationTitle, setNotificationTitle] = React.useState(null);
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    // Start Fetching Partners List
    if (!loader) {
      if (!at(fetchDataResponse, `${FETCH_DATA_PARTNER}.${IS_INITIATED}`)) {
        dispatch(
          fetchData({
            key: FETCH_DATA_PARTNER,
            method: GET,
            token: true,
          })
        );
        setLoader(true);
      }
      if(query.get('id')) {
        if (!at(fetchDataResponse, `${FETCH_PARTNER}.${query.get('id')}.${IS_INITIATED}`)) {
          dispatch(
            fetchData({
              key: FETCH_PARTNER,
              subKey:  `${query.get('id')}`,
              method: GET,
              token: true,
              req: {
                partner_id:  query.get('id')
              }
            })
          );
          setLoader(true);
        }
      } else {
        if(!(view === DEFAULT)){
          setView(DEFAULT)
        }
      }
    }
    // Save Partner

    if(loader) {
      if(query.get('id') && !partner) {
        if (at(fetchDataResponse, `${FETCH_PARTNER}.${query.get('id')}.${IS_DONE}`)) {
          setPartner(at(fetchDataResponse, `${FETCH_PARTNER}.${query.get('id')}.data`))
          setLoader(false)
        }
      }
      
    }
    if(at(executeDataResponse, `${VERIFICATION_EXECUTE}.${IS_DONE}`)) {
      setLoader(false);
      dispatch(clearDataExecuteData({
        key: VERIFICATION_EXECUTE
      }));
      setPartner(null);
      setData([]);
      dispatch(clearData({
        key: FETCH_PARTNER
      }))
      dispatch(clearData({
        key: FETCH_DATA_PARTNER
      }))
    }
    if(at(executeDataResponse, `${VERIFICATION_EXECUTE}.${IS_ERROR}`)) {
      setLoader(false);
      dispatch(clearDataExecuteData({
        key: VERIFICATION_EXECUTE
      }));
      setPartner(null);
      setData([]);
      dispatch(clearData({
        key: FETCH_PARTNER
      }))
      dispatch(clearData({
        key: FETCH_DATA_PARTNER
      }))
    }
    // Save Fetched Partners List
    if (
      loader ||
      (data.length === 0 &&
        at(fetchDataResponse, `${FETCH_DATA_PARTNER}.data`)?.length > 0)
    ) {
      if (at(fetchDataResponse, `${FETCH_DATA_PARTNER}.${IS_DONE}`)) {
        const partnersArrayTemp = [];
        for (const partner of at(
          fetchDataResponse,
          `${FETCH_DATA_PARTNER}.data`
        )) {
          const partnerObject = partner;
          partnerObject.phoneNumber = at(
            partner,
            "userInfo.providerData.0.phoneNumber"
          );
          partnerObject.creationTime = moment(
            at(partner, "userInfo.metadata.creationTime")
          ).format("MM/DD/YYYY");
          partnerObject.city = [];
          partnerObject.region = [];

          partnerObject.city.push(at(partner, "address.city"));
          partnerObject.region.push(
            at(partner, "address.state")
              ? at(partner, "address.state")
              : at(partner, "address.region")
          );

          partnersArrayTemp.push(partnerObject);
        }
        setData(partnersArrayTemp);
        setLoader(false);
      }
    }
  }, [loader, fetchDataResponse, dispatch, data.length, query, view, executeDataResponse, partner]);

  const handleTableRowClick = (row) => {
    setPartner(row);
    setView(DETAILED)
    history.push(`?id=${row?.userInfo?.uid}`)
  };
const handleOnBack = () => {
  setView(DEFAULT)
  setPartner(null);
  history.push('/dashboard/partners')
}

const handleRequestDocuments = () => {
  if(!at(executeDataResponse, `${VERIFICATION_EXECUTE}.${IS_INITIATED}`)){
    setLoader(true)
      dispatch(executeData({
        key: VERIFICATION_EXECUTE,
        token: true,
        method: POST,
        req: {
          status: REQUEST,
          id: partner?.userInfo?.uid
        }
      }))
  }
}
const handleRejectClick = () => {
  if(!at(executeDataResponse, `${VERIFICATION_EXECUTE}.${IS_INITIATED}`)){
    setLoader(true)
      dispatch(executeData({
        key: VERIFICATION_EXECUTE,
        token: true,
        method: POST,
        req: {
          status: REJECT,
          id: partner?.userInfo?.uid
        }
      }))
  }
}

const handleVerifyClick = () => {
  if(!at(executeDataResponse, `${VERIFICATION_EXECUTE}.${IS_INITIATED}`)){
    setLoader(true)
      dispatch(executeData({
        key: VERIFICATION_EXECUTE,
        token: true,
        method: POST,
        req: {
          status: VERIFY,
          id: partner?.userInfo?.uid
        }
      }))
  }
}
const handlePushNotification = () => {
  try {
    const usersAndToken = [];
    data.map((each) => {
      usersAndToken.push({
        displayName: each.displayName,
        notificationToken: each.notificationToken,
      });
      return null;
    });
    setUsersAndToken(usersAndToken);
    setModal(true);
    console.log(data);
  } catch (error) {}
};

const handlePushNotificationSent = () => {
  setModal(false);
  dispatch(
    executeData({
      key: "PUSHNOTIFICATION",
      token: true,
      method: POST,
      req: {
        userData: [
          {
            displayName: "Tony",
            notificationToken: {
              type: "expo",
              data: "ExponentPushToken[DJLEkWEFzPYLhnx8upGVyD]",
            },
          },
        ],
        body: notificationMessage,
        title: notificationTitle,
      },
    })
  );
};
  return (
    <div style={{width: '100%', height: '100%'}}>
      {loader && <CircularProgress />}
      {view === DEFAULT && (
        <Box>
          <Box id="top_action_bar" marginTop={1} marginY={1} marginX={2}>
        <Grid
          container
          direction="row-reverse"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              width="100%"
              onClick={handlePushNotification}
              color="primary"
              disableElevation
            >
              {i18n.t("sent_notification")}
            </Button>
          </Grid>
        </Grid>
      </Box>
           <FullScreenDialog
        modal={modal}
        content={
          <Box m={2} flexDirection={"column"}>
            <Box marginY={2}>
              {/* <TextField
                label={i18n.t("notification_label")}
                multiline
                style={{ width: "80%" }}
                disabled
                variant="outlined"
              /> */}
              {usersAndToken?.map((item) => (
                <Chip
                  color="primary"
                  label={item?.displayName}
                  variant="outlined"
                />
              ))}
            </Box>
            <Box marginY={2}>
              <TextField
                style={{ width: "80%" }}
                label={i18n.t("notification_title")}
                multiline
                rows={1}
                onChange={(e) => {
                  setNotificationTitle(e?.target?.value);
                }}
                variant="outlined"
              />
            </Box>
            <Box marginY={2}>
              <TextField
                style={{ width: "80%" }}
                label={i18n.t("notification_placeholder")}
                multiline
                rows={4}
                onChange={(e) => {
                  setNotificationMessage(e?.target?.value);
                }}
                variant="outlined"
              />
            </Box>
            <Box marginY={2}>
              <Button
                variant="outlined"
                onClick={handlePushNotificationSent}
                color="primary"
                disableElevation
              >
                {i18n.t("sent")}
              </Button>
            </Box>
          </Box>
        }
        setModal={(value) => setModal(value)}
        title={i18n.t("sent_notification")}
        right={false}
      />
        <Table
          rows={data}
          onClick={(row) => handleTableRowClick(row)}
          title={i18n.t("partners")}
          loader={loader}
          header={header}
        />
        </Box>
      )}
      {view === DETAILED && <PartnerProfile  rejectClick={handleRejectClick} verifyClick={handleVerifyClick} requestDocuments={handleRequestDocuments} partner={partner} onBack={handleOnBack} />}
    </div>
  );
};

export default Partner;
